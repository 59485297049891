<template>

    <div>
        <label for="textarea">Metin</label>
        <at-ta at="{"
               :members="keyler"
               tabSelect
               suffix="} ">
            <b-form-textarea class="editor"
                             id="textarea"
                             v-model="metin"
                             rows="7"></b-form-textarea>
        </at-ta>
        <span class="text-muted small mt-3">
            {{yazilanToplamKarakterSayisi}}/280
        </span>
        <b-button block variant="success" @click="Ekle">KAYDET</b-button>

    </div>

</template>

<script>
    import AtTa from 'vue-at/dist/vue-at-textarea'
    import {HepsininAdlariniGetir} from "@/api/dkelime"
    import {HataliMesajiniGoster} from "@/messages"
    import {EkleVeyaDuzenle, Getir} from "@/api/sablon"
    import {BasariliMesajiniGoster} from "@/messages"

    export default {
        name: 'sabloneklevayaduzenle',
        data () {
            return {
                metin: '',
                yazilanToplamKarakterSayisi: 0,
                keyler: []
            }
        },
        methods: {
            async Ekle() {
                if (this.metin === "")
                    return HataliMesajiniGoster("Şablon Metni Boş Bırakılamaz")

                let id = 0;
                const sablonId = this.$route.params.sablonId
                if (sablonId)
                    id = sablonId

                try {
                    const {message} = await EkleVeyaDuzenle({
                        Id: id,
                        Metin: this.metin,
                        IslemiYapanYetkiliKisiId: this.$store.getters.kisi.id
                    })
                    this.$router.go(-1)
                    BasariliMesajiniGoster(message)
                }
                catch(err) {
                    console.log(err.response)
                }
            }
        },
        watch: {
            metin: function (val) {
                this.yazilanToplamKarakterSayisi = val.length
            }
        },
        async created() {
            const { data } = await HepsininAdlariniGetir()
            this.keyler = data;

            const sablonId = this.$route.params.sablonId
            if (sablonId) {
                const { data } = await Getir(sablonId)
                this.metin = data.metin
            }
        },
        components: { AtTa },
    }
</script>
